<template>
  <div v-loading="isLoading" class="bind-creative">
    <div v-if="!isLoading" class="bind-creative__content">
      <div class="bind-creative__title">
        Привязать токен
      </div>
      <div class="bind-creative__steps">
        <div class="bind-creative__step">
          <div class="bind-creative__step-title">
            1. Какие рекламные аккаунты выбрать
          </div>
          <div class="bind-creative__step-sub-title">
            Рекламные аккаунты
          </div>
          <div class="bind-creative__step-content">
            <div 
              v-if="accountsListWithCampaigns && accountsListWithCampaigns.length" 
              class="bind-creative__step-account-selector bind-creative__step-account-selector--ad"
            >
              <div 
                v-if="form.adAccount" 
                class="bind-creative__step-account-selector-value" 
                @click="showOptions = !showOptions"
              >
                <span class="bind-creative__step-account-selector-name">
                  <img
                    v-if="form.adAccount.service === 'mytarget'"
                    src="@/assets/icons/icon-mytarget-logo.svg"
                    alt="platform-logo"
                  >
                  <img
                    v-if="form.adAccount.service === 'vkontakte'"
                    src="@/assets/icons/icon-vkontacte.svg"
                    alt="platform-logo"
                  >
                  <img
                    v-if="form.adAccount.service === 'vkads'"
                    src="@/assets/icons/icon-vkreklama.svg"
                    alt="platform-logo"
                  >
                  <img
                    v-if="form.adAccount.service === 'facebook'"
                    src="@/assets/icons/icon-meta.svg"
                    alt="platform-logo"
                  >
                  <img
                    v-if="form.adAccount.service === 'yandex'"
                    src="@/assets/icons/yandex-direct-icon.svg"
                    alt="platform-logo"
                  >
                  <img
                    v-if="form.adAccount.service === 'google'"
                    src="@/assets/icons/icon-google.svg"
                    alt="platform-logo"
                  >
                  <img
                    v-if="form.adAccount.service === 'tiktok'"
                    src="@/assets/icons/icon-tiktok.svg"
                    alt="platform-logo"
                  >
                  <img
                    v-if="form.adAccount.service === 'telegram'"
                    src="@/assets/icons/icon-telegram.svg"
                    alt="platform-logo"
                  >
                  <img
                    v-if="form.adAccount.service === 'promopages'"
                    src="@/assets/icons/icon-promo-page.jpeg"
                    alt="platform-logo"
                  >
                    <!-- {{ selectedAccount.service }} -->
                </span>
                <span class="bind-creative__step-account-selector-info">
                  <span
                    v-if="form.adAccount.description"
                    class="bind-creative__step-account-selector-desc"
                    v-html="form.adAccount.description"
                  />
                  <span class="bind-creative__step-account-selector-title">
                      {{ form.adAccount ? form.adAccount.account_name : '' }}
                  </span>
                  <img
                    class="bind-creative__step-account-selector-arrow"
                    :class="{ active: showOptions }"
                    src="@/assets/icons/icon-arrow.svg"
                    alt="arrow"
                  >
                </span>
              </div>
              <div v-show="showOptions" class="bind-creative__step-account-selector-options">
                <div
                    class="form-input bind-creative__step-account-selector-search"
                    :class="
                  [
                    {'form-input--full' : search}
                  ]"
                >
                  <input v-model="search" type="text">
                  <span class="form-input__label">
                    {{ $t('search_props.placeholder') }}
                  </span>
                </div>
                <div
                    v-for="item in filtredAccountsList"
                    :key="item.id"
                    class="bind-creative__step-account-selector-item"
                    @click="selectAccount(item)"
                >
                  <span class="bind-creative__step-account-selector-service">
                    <!-- {{ item.service }} -->
                    <img 
                    :src="getLogo(item.service)"
                    alt="add account logo">
                  </span>
                  <span class="bind-creative__step-account-selector-info">
                    <span
                      v-if="item.description"
                      class="bind-creative__step-account-selector-desc"
                      v-html="item.description"
                    />
                    <span class="bind-creative__step-account-selector-title">
                      {{ item.account_name }}
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="bind-creative__step">
          <div class="bind-creative__step-title">
            2. Какие рекламные креативы привязать
          </div>
          <div class="bind-creative__step-sub-title">
            Рекламные креативы
          </div>
          <div class="bind-creative__step-content">
            <div class="bind-creative__step-account-selector">
              <div class="bind-creative__step-account-selector-value" @click="showOptionsCreative = !showOptionsCreative">
                <span class="bind-creative__step-account-selector-name">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M18.5 12.6484V16.3484C18.5 19.4684 15.59 21.9984 12 21.9984C8.41 21.9984 5.5 19.4684 5.5 16.3484V12.6484C5.5 15.7684 8.41 17.9984 12 17.9984C15.59 17.9984 18.5 15.7684 18.5 12.6484Z" stroke="#395FE4" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M18.5 7.65C18.5 8.56 18.25 9.4 17.81 10.12C16.74 11.88 14.54 13 12 13C9.46 13 7.26 11.88 6.19 10.12C5.75 9.4 5.5 8.56 5.5 7.65C5.5 6.09 6.22999 4.68 7.39999 3.66C8.57999 2.63 10.2 2 12 2C13.8 2 15.42 2.63 16.6 3.65C17.77 4.68 18.5 6.09 18.5 7.65Z" stroke="#395FE4" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M18.5 7.65V12.65C18.5 15.77 15.59 18 12 18C8.41 18 5.5 15.77 5.5 12.65V7.65C5.5 4.53 8.41 2 12 2C13.8 2 15.42 2.63 16.6 3.65C17.77 4.68 18.5 6.09 18.5 7.65Z" stroke="#395FE4" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                  <span>{{ form.creative && form.creative.erid || 'Токен'}}</span>
                </span>
                <span class="bind-creative__step-account-selector-info">
                  <span
                    class="bind-creative__step-account-selector-desc"
                  >{{ form.creative && form.creative.creativeGroupName || 'Медиаданные креатива'}}</span>
                  <span class="bind-creative__step-account-selector-title">
                    {{ form.creative.creativeGroupStartDate && fixDateFormatWithDots(form.creative.creativeGroupStartDate) || '-' }}
                  </span>
                  <img
                    class="bind-creative__step-account-selector-arrow"
                    :class="{ active: showOptionsCreative }"
                    src="@/assets/icons/icon-arrow.svg"
                    alt="arrow"
                  >
                </span>
              </div>
              <div v-show="showOptionsCreative" class="bind-creative__step-account-selector-options">
                <div
                  class="form-input bind-creative__step-account-selector-search"
                  :class="{ 'form-input--full' : searchCreative }"
                >
                  <input v-model="searchCreative" type="text">
                  <span class="form-input__label">
                    {{ $t('search_props.placeholder') }}
                  </span>
                </div>
                <div
                  v-for="creative in filteredCreatives"
                  :key="creative.id"
                  class="bind-creative__step-account-selector-item"
                  @click="form.creative = creative; showOptionsCreative = false;"
                >
                  <span class="bind-creative__step-account-selector-service">
                    {{ creative.erid }}
                  </span>
                  <span class="bind-creative__step-account-selector-info">
                    <span
                      class="bind-creative__step-account-selector-desc"
                    >{{ creative.creativeGroupName }}</span>
                    <span class="bind-creative__step-account-selector-title">
                      {{ creative.creativeGroupStartDate && fixDateFormatWithDots(creative.creativeGroupStartDate) || '-' }}
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bind-creative__final">
      <ButtonElement 
        text="Привязать" 
        :disabled="isBtnDisabled"
        @click-button="bindCreative" 
      />
    </div>
  </div>
</template>

<script>
import ButtonElement from "@/components/elements/ButtonElement";
import axios from "axios";
import { mapState, mapMutations, mapActions } from 'vuex'
import fixDateFormat from '@/mixin/fixDate'

export default {
  name: 'BindCreative',
  components: {
    ButtonElement,
  },
  mixins: [fixDateFormat],
  data () {
    return {
      showOptionsCreative: false,
      searchCreative: "",
      isLoading: true,
      creatives: [],
      form: {
        creative: null,
        adAccount: null,
      },
      selectedAccount: null,
      accountsListWithCampaigns: [],
      showOptions: false,
      search: "",
    }
  },
  computed: {
    ...mapState('Creatives', ['chosenCreative', 'filters']),
    isBtnDisabled() {
      return !(this.form.creative && this.form.adAccount)
    },
    filtredAccountsList() {
      if (this.search && this.accountsListWithCampaigns) {
        return this.accountsListWithCampaigns.filter(
          el => 
          ((el.description && el.description.toLowerCase().includes(this.search.toLowerCase())) ||
          (el.account_name && el.account_name.toLowerCase().includes(this.search.toLowerCase()))) &&
          el.service === 'telegram'
        )
      } else if (this.accountsListWithCampaigns) {
        return this.accountsListWithCampaigns.filter(el => el.service === 'telegram')
      }
      return []
    },
    filteredCreatives() {
      if (this.searchCreative && this.creatives.length) {
        return this.creatives.filter((creative) => {
          return creative.description && creative.description.toLowerCase().includes(this.searchCreative.toLowerCase())
        })
      } else if (this.creatives.length) {
        return this.creatives
      }
      return []
    },
    activeAccount() {
      return this.$store.state.Auth.activeAccount
    },
    getClientId() {
      return this.$route.query.clientId
    },
    getInitialContractId() {
      return this.$route.query.initialContractId
    },
    getFinalContractId() {
      return this.$route.query.finalContractId
    }
  },
  watch: {
    
  },
  methods: {
    ...mapMutations('Creatives', ['setChosenCreative']),
    ...mapActions('Creatives', ['bindOrdToken']),
    getLogo(data) {
      let result = ''
      if(data==='mytarget') { 
        result = require("@/assets/icons/icon-mytarget-logo.svg")
      }else if (data==='vkontakte') {
        result = require("@/assets/icons/icon-vkontacte.svg")
      }else if (data==='vkads') {
        result = require("@/assets/icons/icon-vkreklama.svg")
      }else if (data==='facebook') {
        result = require("@/assets/icons/icon-meta.svg")
      }else if (data==='yandex') {
        result = require("@/assets/icons/yandex-direct-icon.svg")
      }else if (data==='google') {
        result = require("@/assets/icons/icon-google.svg")
      }else if (data==='tiktok') {
        result = require("@/assets/icons/icon-tiktok.svg")
      }else if (data==='telegram') {
        result = require("@/assets/icons/icon-telegram.svg")
      }else if (data==='promopages') {
        result = require("@/assets/icons/icon-promo-page.jpeg")
      }
      return result
    },
    bindCreative() {
      const data = {
        text: this.form.creative.description,
        token:this.form.creative.erid,
        id: this.form.adAccount.id
      }
      this.bindOrdToken(data)
      .then(()=> {
        this.$notify({
          message: this.$t('token.success'),
          type: 'success'
        });
      })
      .catch(() => {
        this.$notify({
          message: this.$t('token.error'),
          type: 'error'
        });
      })
    },
    fetchAdAccounts() {
      return new Promise((resolve, reject) => {
        axios({ url: process.env.VUE_APP_API_URL + `/advertservice/active-accounts-in-services?business_account_id=${this.activeAccount.id}`, method: 'GET' })
          .then(({ data }) => {
            if (data.data) {
              for (let key in data.data) {
                if (key) this.accountsListWithCampaigns = this.accountsListWithCampaigns.concat(data.data[key])
              }
              this.accountsListWithCampaigns = this.accountsListWithCampaigns.filter(el => el.service === 'telegram' && this.activeAccount.visible_services[el.service])
            }
            this.form.adAccount = this.$store.state.Balance.filledAccount 
              ? this.$store.state.Balance.filledAccount 
              : this.accountsListWithCampaigns[0]
            
            resolve(data); 
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    fetchCreatives() {
      return new Promise((resolve, reject) => {
        this.$store.dispatch('Creatives/getAllCreatives', {
        ...this.filters,
        ...(this.filters.creativeGroupStartDate && { creativeGroupStartDate: this.fixDateFormatNumbers(this.filters.creativeGroupStartDate) }),
        ...(this.filters.creativeGroupEndDate && { creativeGroupEndDate: this.fixDateFormatNumbers(this.filters.creativeGroupEndDate) }),
        ...(this.getFinalContractId && { finalContractId: this.getFinalContractId }),
        ...(this.getInitialContractId && { initialContractId: this.getInitialContractId }),
        ...(this.getClientId && { contractorId: this.getClientId }),
      })
        .then((creatives) => {
          this.creatives = creatives  
            resolve(creatives)
          })
        .catch((error) => {
          reject(error)
        })
      })
    },
    selectAccount(item) {
      this.form.adAccount = item; 
      this.showOptions = false
    }
  },
  created() {
    if (this.activeAccount) {
      Promise.all([
      this.fetchCreatives(),
      this.fetchAdAccounts()
    ])
      .then((data) => {
        if (this.chosenCreative && data.length) {
          this.form.creative = this.chosenCreative
        } else {
          this.form.creative = this.creatives[0]
        }
      })
      .catch((error) => {
        console.error('An error occurred:', error)
      })
      .finally(() => {
        this.isLoading = false
      })
    }

   

    document.addEventListener('click', (e) => {
      if (!e.target.closest('.bind-creative__step-account-selector')) {
        this.showOptionsCreative = false
      }
    }, true)

    document.addEventListener('click', (e) => {
      if (!e.target.closest('.bind-creative__step-account-selector--ad')) {
        this.showOptions = false
      }
    }, true)
  },
  beforeDestroy() {
    this.setChosenCreative(null)
  },
}
</script>

<style scoped lang="scss">
@import "@/assets/styles/helpers/variables";
@import "@/assets/styles/helpers/mixin";

.bind-creative {
  background: #fff;
  color: #000;
  min-height: 100dvh;
  display: flex;
  flex-direction: column;

  &__title {
    font-weight: 700;
    font-size: 3rem;
    line-height: 3.5rem;
    margin-bottom: 5rem;
  }

  &__content {
    padding: 7rem 6rem;
  }

  &__final {
    width: 100%;
    background: #EFF1F4;
    padding: 3.3rem 6rem;
    display: flex;
    justify-content: flex-end;
    margin-top: auto;

    .button {
      width: 14rem;
      height: 5rem;
    }
  }

  &__step {
    margin-bottom: 4.4rem;

    &:last-of-type {
      margin-bottom: 0;
    }

    &-none {
      font-size: 1.8rem;
    }

    &-sub-title {
      font-size: 1.8rem;
      font-weight: 700;
      line-height: 2.1rem;
      margin-bottom: .9rem;
    }

    &-title {
      font-weight: 700;
      font-size: 2rem;
      line-height: 2.3rem;
      color: #333333;
      margin-bottom: 1.8rem;

      &--small {
        font-size: 1.8rem;
        line-height: 2.1rem;
        margin-bottom: 0.9rem;
      }
    }

    &-content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      position: relative;
      &.add-x-scroll {
         overflow-x: auto;
         min-height: 55rem;
      }
    }

    &-subtitle {
      font-size: 1.6rem;
      line-height: 1.9rem;
      color: #7F858D;
      margin-bottom: 0.9rem;
    }

    &-account-selector {
      width: auto;
      min-width: 41rem;
      margin-bottom: 1.4rem;
      position: relative;

      &-value {
        border: 1px solid #D1D4D8;
        border-radius: 2px;
        height: 5rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 5rem 0 1.5rem;
        cursor: pointer;
      }

      &-name {
        display: flex;
        align-items: center;
        gap: 1rem;

        &>img {
          margin-right: 0.5rem;
          width: 2rem;
          height: 2rem;
          display: block;
        }
      }

      &-arrow {
        position: absolute;
        right: 2rem;
        transition: 0.3s ease-out;
        top: calc(50% - 8px);

        &.active {
          transform: rotate(-180deg);
        }
      }

      &-options {
        position: absolute;
        border: 1px solid #BFC2C6;
        border-radius: 2px;
        background: #fff;
        z-index: 10;
        width: 100%;
        left: 0;
        top: calc(100% + 5px);
        max-height: 40rem;
        overflow-y: auto;
        padding: 0 2rem;
      }

      &-desc {
        font-size: 1.2rem;
        line-height: 1.4rem;
        color: #333333;
      }

      &-title {
        font-size: 1.4rem;
        line-height: 1.6rem;
        color: #7F858D;
        font-weight: 400;
      }

      &-item {
        height: 5.8rem;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
        transition: 0.3s ease-out;
        font-size: 1.4rem;
        border-bottom: 1px solid #EDEFF3;

        &:last-of-type {
          border-bottom: none;
        }

        &:hover {
          color: var(--primary);
        }
      }

      &-service {
        font-weight: 700;
        &>img {
          display: block;
          max-width: 2rem;
          width: 100%;
        }
      }

      &-info {
        text-align: right;
        display: flex;
        flex-direction: column;
      }

      &-error {
        color: $redLight;
        margin-top: 2rem;
      }

      &-search {
        margin: 10px 0;
      }
    }
  }
}

@include below(993px) {
  .bind-creative__final,
  .bind-creative__content{
    padding: 15px;
  }
}

@include below(769px) {
  .bind-creative__step-col{
    margin-right: 0;
    width: 100%;
  }
  .bind-creative__title{
    margin-right: 35px;
    font-size: 22px;
    line-height: 1.3;
    margin-bottom: 30px;
    word-break: break-word;
  }
}

@include below(500px) {
  .bind-creative__final{
    flex-wrap: wrap;
    .button{
      width: 100%;
    }
  }
  .bind-creative__final-block{
    width: 100%;
    padding-right: 0;
  }
}

</style>
